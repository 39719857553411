import { axios } from '@/utils/request'

export function getFmsInfoPage (type, parameter) {
  return axios({
    url: `fms/${type}/list`,
    method: 'post',
    data: parameter
  })
}

export function getBizAuditPage (type, parameter) {
  return axios({
    url: `fms/business_audit/${type}`,
    method: 'post',
    data: parameter
  })
}

export function getBizCheckPage (type, parameter) {
  return axios({
    url: `fms/business_audit/${type}`,
    method: 'post',
    data: parameter
  })
}

export function getFmsInfoDetail (type, params) {
  return axios({
    url: `fms/${type}/detail`,
    method: 'get',
    params
  })
}

// 根据财商id获取对应开票信息集合
export function getInvoiceDataByAuditId(params) {
  return axios({
    url: `fms/business_audit/getInvoiceDataByAuditId`,
    method: `get`,
    params
  })
}

// 获取商务审核开票信息
export function getFmsInvoiceInfo (data) {
  return axios({
    url: `fms/business_audit/get_invoice_info`,
    method: 'post',
    data
  })
}

export function checkBeforeApprove (data) { // 审核通过
  return axios({
    url: `fms/business_audit/checkBeforeApprove`,
    method: 'post',
    data
  })
}

export function approveFmsInfo (data) { // 审核通过
  return axios({
    url: `fms/business_audit/approve`,
    method: 'post',
    data
  })
}

export function unApproveFmsInfo (data) { // 反审核
  return axios({
    url: `fms/business_audit/unApprove`,
    method: 'post',
    data
  })
}

export function denyFmsInfo (data) { // 审核驳回
  return axios({
    url: `fms/business_audit/deny`,
    method: 'post',
    data
  })
}

export function makeInvoiceInfo (data) { // 开票
  return axios({
    url: `fms/business_audit/invoice`,
    method: 'post',
    data
  })
}

export function fmsSaveInvoiceNumber (data) { // 应付记录关联发票号
  return axios({
    url: `fms/business_audit/save_invoice_number`,
    method: 'post',
    data
  })
}

export function fmsSaveInvoice(data) { // 更新发票信息
  return axios({
    url: `fms/business_audit/save_invoice`,
    method: `post`,
    data
  })
}

export function fmsBizAuditCheck (data) { // 核销
  return axios({
    url: `fms/business_audit/check`,
    method: 'post',
    data
  })
}

export function fmsBizAuditUnCheck (data) { // 反核销
  return axios({
    url: `fms/business_audit/uncheck`,
    method: 'post',
    data
  })
}

export function fmsBizAuditFunc (type, data) {
  return axios({
    url: `fms/business_audit/${type}`,
    method: 'post',
    data
  })
}

export function fmsBizFinanceFunc (type, data) {
  return axios({
    url: `fms/finance/${type}`,
    method: 'post',
    data
  })
}

export function getFmsMetaOption(type, parameter) {
  return axios({
    url: `fms/${type}/option`,
    method: 'get',
    params: parameter
  })
}

export function getFmsMetaPage(type, parameter) {
  return axios({
    url: `fms/${type}/list`,
    method: 'get',
    params: parameter
  })
}

export function getFmsMoneyRegisterPage(type, parameter) {
  return axios({
    url: `fms/${type}/getFmsMoneyRegisterPage`,
    method: 'get',
    params: parameter
  })
}

export function createFmsMetaInfo(type, data) {
    return axios({
      url: `fms/${type}/create`,
      method: 'post',
      data
    })
}

export function saveFmsMetaInfo(type, data) {
  return axios({
    url: `fms/${type}/save`,
    method: 'post',
    data
  })
}

export function updateFmsMetaInfo(type, data) {
    return axios({
        url: `fms/${type}/update`,
        method: 'post',
        data
    })
}

export function deleteFmsMetaInfo(type, data) {
  return axios({
      url: `fms/${type}/delete`,
      method: 'post',
      data
  })
}

export function getFmsFinancePage(type, parameter) {
  return axios({
    url: `fms/finance/${type}`,
    method: 'get',
    params: parameter
  })
}

// 资金认领
export function claimFmsFinanceInfo(data) {
  return axios({
    url: `fms/finance/claim`,
    method: 'post',
    data
  })
}

// 资金反认领
export function reverseClaimFmsFinanceInfo(data) {
  return axios({
    url: `fms/finance/reverse_claim`,
    method: `post`,
    data
  })
}

export function exportInvoiceData(data) {
  return axios({
    url: `fms/business_audit/export_invoice_data`,
    method: 'get',
    params: data
  })
}

export function invoiceListInfo(data) {
  return axios({
    url: `fms/business_audit/invoice_list`,
    method: 'post',
    data
  })
}

export function invoiceSaleListInfo(data) {
  return axios({
    url: `fms/business_audit/invoice_sale_list`,
    method: 'post',
    data
  })
}

export function invoiceListInfoTotal(data) {
  return axios({
    url: `fms/business_audit/invoice_list_total`,
    method: 'post',
    data
  })
}

export function cashImport(data) {
  return axios({
    url: `fms/finance/cash_import`,
    method: 'post',
    data
  })
}

export function getChargeQrCode(data) {
  return axios({
    url: `fms/business_audit/qrcode`,
    method: 'post',
    data
  })
}

export function downloadCertById(data) {
  return axios({
    url: `fms/business_audit/downloadCert`,
    method: 'post',
    data
  })
}

export function previewCertById(data) {
  return axios({
    url: `fms/business_audit/getCertDetail`,
    method: 'get',
    params: data
  })
}

export function delCert(params) {
  return axios({
    url: `fms/business_audit/delCert`,
    method: 'post',
    params
  })
}

export function certConfirm(params) {
  return axios({
    url: `fms/business_audit/certConfirm`,
    method: 'post',
    params
  })
}

export function certUnConfirm(params) {
  return axios({
    url: `fms/business_audit/certUnConfirm`,
    method: 'post',
    params
  })
}

export function generateCommonCert(params) {
  return axios({
    url: `fms/business_audit/generateCommonCert`,
    method: 'post',
    params
  })
}

export function batchGenerateCommonCert(data) {
  return axios({
    url: `fms/business_audit/batchGenerateCommonCert`,
    method: 'post',
    data
  })
}

export function batchCertConfirm(data) {
  return axios({
    url: `fms/business_audit/batchCertConfirm`,
    method: 'post',
    data
  })
}

export function batchGeneratePayCert(data) {
  return axios({
    url: `fms/business_audit/batchGeneratePayCert`,
    method: 'post',
    data
  })
}
export function generateChargeCert(params) {
  return axios({
    url: `fms/finance/generateCert`,
    method: 'post',
    params
  })
}

// 生成堆场专属凭证
export function generateYmsCert(data) {
  return axios({
    url: `yms/manager/generateYmsCert`,
    method: 'post',
    data
  })
}

// 生成仓储专属凭证
export function generateWmsCert(data) {
  return axios({
    url: `fms/business_audit/generateWmsCert`,
    method: 'post',
    data
  })
}

export function generatePayCert(params) {
  return axios({
    url: `fms/business_audit/generatePayCert`,
    method: 'post',
    params
  })
}

// 资金出纳
export function fundCashier(data) {
  return axios({
    url: `fms/business_audit/fundCashier`,
    method: `post`,
    data
  })
}

// 删除资金出纳
export function deleteFundCashier(data) {
  return axios({
    url: `fms/business_audit/deleteFundCashier`,
    method: `post`,
    data
  })
}

// 编辑是否预收
export function editIsAdvance(data) {
  return axios({
    url: `fms/finance/editIsAdvance`,
    method: `post`,
    data
  })
}

// 编辑实际付款人
export function editPayer(data) {
  return axios({
    url: `fms/finance/editPayer`,
    method: `post`,
    data
  })
}

export function downloadAdvanceCertById(data) {
  return axios({
    url: `fms/finance/downloadCert`,
    method: 'get',
    params: data
  })
}

export function previewAdvanceCertById(data) {
  return axios({
    url: `fms/finance/getCertDetail`,
    method: 'get',
    params: data
  })
}

export function advanceCertConfirm(params) {
  return axios({
    url: `fms/finance/certConfirm`,
    method: 'post',
    params
  })
}

export function advanceCertUnConfirm(params) {
  return axios({
    url: `fms/finance/certUnConfirm`,
    method: 'post',
    params
  })
}

export function generateAdvanceCert(params) {
  return axios({
    url: `fms/finance/generateAdvanceCert`,
    method: 'post',
    params
  })
}

export function batchGenerateChargeCert(data) {
  return axios({
    url: `fms/finance/batchGenerateChargeCert`,
    method: 'post',
    data
  })
}

export function getInvoiceRecordByAuditRecordId(params) {
  return axios({
    url: `fms/invoice_record/getInvoiceRecordByAuditRecordId`,
    method: `get`,
    params
  })
}

export function getInvoiceSheetBill(params) {
  return axios({
    url: `fms/business_audit/getInvoiceSheetBill`,
    method: `get`,
    params
  })
}

export function getMmsSheetBill(params) {
  return axios({
    url: `fms/business_audit/getRentFeeSheetBill`,
    method: `get`,
    params
  })
}

export function getPaymentSheetBill(params) {
  return axios({
    url: `fms/business_audit/getPaymentSheetBill`,
    method: `get`,
    params
  })
}

export function getTempDropBoxSheetBill(params) {
  return axios({
    url: `fms/business_audit/getTempDropBoxSheetBill`,
    method: `get`,
    params
  })
}

export function getTaxRateById(params) {
  return axios({
    url: `/fms/tax_rate/getTaxRateById`,
    method: `get`,
    params
  })
}

export function getClaimInfo(params) {
  return axios({
    url: `/fms/finance/get_claim_info`,
    method: `get`,
    params
  })
}

export function getClaimRecord(params) {
  return axios({
    url: `/fms/finance/get_claim_record`,
    method: `get`,
    params
  })
}

export function getClaimOrderList(params) {
  return axios({
    url: `/fms/finance/get_order_list`,
    method: `get`,
    params
  })
}

export function getClaimPage(params) {
  return axios({
    url: `/fms/finance/list_claim`,
    method: `post`,
    data: params
  })
}

export function autoClaimMoney(params) {
  return axios({
    url: `/fms/finance/autoClaimMoney`,
    method: `post`,
    params
  })
}

export function scanQRCodeCharge(params) {
  return axios({
    url: `/fms/business_audit/scanQRCodeCharge`,
    method: `post`,
    data: params
  })
}

export function posCharge(params) {
  return axios({
    url: `/fms/business_audit/posCharge`,
    method: `post`,
    data: params
  })
}

export function anewScanQRCodeCharge(params) {
  return axios({
    url: `/fms/business_audit/anewScanQRCodeCharge`,
    method: `post`,
    params
  })
}

export function getWmsRecord(params) {
  return axios({
    url: `/fms/business_audit/getWmsRecord`,
    method: `post`,
    data: params
  })
}

export function cancelCashCharge(params) {
  return axios({
    url: `/fms/business_audit/cancelCashCharge`,
    method: `post`,
    params
  })
}

export function getchargeTax() {
  return axios({
    url: `/fms/tax_rate/getChargeTax`,
    method: `get`
  })
}

export function getPurchaseInfo(params) {
  return axios({
    url: `/fms/business_audit/getPurchaseInfo`,
    method: `post`,
    data: params
  })
}

export function generatePurchaseCert(params) {
  return axios({
    url: `fms/business_audit/generatePurchaseCert`,
    method: 'post',
    params
  })
}

export function getTicketInfo(data) {
  return axios({
    url: 'yms/manager/getTicketInfo',
    method: 'post',
    data
  })
}

export function createInvoiceRecord(data) {
  return axios({
    url: 'yms/manager/createInvoiceRecord',
    method: 'post',
    data
  })
}

export function exportWmsPosInvoiceSettlementSheet(params) {
  return axios({
    url: 'fms/business_audit/exportWmsPosInvoiceSettlementSheet',
    method: 'get',
    params
  })
}

export function exportPayIncomeTotalSheet(params) {
  return axios({
    url: `fms/business_audit/exportPayIncomeTotalSheet`,
    method: 'get',
    params
  })
}

export function exportChargeTotalSheet(params) {
  return axios({
    url: `fms/business_audit/exportChargeTotalSheet`,
    method: 'get',
    params
  })
}

export function removeFmsBusinessRecord(data) {
  return axios({
    url: `yms/manager/removeFmsBusinessRecord`,
    method: 'post',
    data
  })
}

export function getFeeInfoListByPage(data) {
  return axios({
    url: `yms/manager/getFeeInfoListByPage`,
    method: 'post',
    data
  })
}

export function listYmsFeeTotal(data) {
  return axios({
    url: `yms/manager/listYmsFeeTotal`,
    method: 'post',
    data
  })
}

export function queryWaitAgree() {
  return axios({
    url: `yms/refund/queryWaitAgree`,
    method: 'get'
  })
}

export function listApplyRefundByPage(data) {
  return axios({
    url: `yms/refund/refundList`,
    method: 'post',
    data
  })
}

export function agreeApplyRefund(data) {
  return axios({
    url: `yms/refund/agreeRefund`,
    method: 'post',
    data
  })
}

export function handleRefuseApply(data) {
  return axios({
    url: `yms/refund/refuseApply`,
    method: 'post',
    data
  })
}

export function exportFeeInfoListSheet(data) {
  return axios({
    url: `yms/manager/exportFeeInfoListSheet`,
    method: 'post',
    data
  })
}

export function getClaimTotalPriceByYardAndPayTime(data) {
  return axios({
    url: `yms/manager/getClaimTotalPriceByYardAndPayTime`,
    method: 'post',
    data
  })
}

export function confirmClaim(data) {
  return axios({
    url: `yms/manager/confirmClaim`,
    method: 'post',
    data
  })
}

export function unconfirmClaim(data) {
  return axios({
    url: `yms/manager/unconfirmClaim`,
    method: 'post',
    data
  })
}

export function getBalanceNoticeSheet(data) {
  return axios({
    url: `yms/manager/getBalanceNoticeSheet`,
    method: 'post',
    data
  })
}

export function getWmsClaimTotalPriceByPayTime(data) {
  return axios({
    url: `fms/business_audit/getWmsClaimTotalPriceByPayTime`,
    method: 'post',
    data
  })
}

export function confirmWmsClaim(data) {
  return axios({
    url: `fms/business_audit/confirmWmsClaim`,
    method: 'post',
    data
  })
}

export function unconfirmWmsClaim(data) {
  return axios({
    url: `fms/business_audit/unconfirmWmsClaim`,
    method: 'post',
    data
  })
}

export function exportTransactionDetialSheet(data) {
  return axios({
    url: `yms/manager/exportTransactionDetialSheet`,
    method: 'post',
    data
  })
}

export function generateYmsCostPayCert(params) {
  return axios({
    url: 'yms/manager/generateYmsCostPayCert',
    method: 'post',
    params
  })
}

export function generateAmortizeYmsCostPayCert(params) {
  return axios({
    url: 'yms/manager/generateAmortizeYmsCostPayCert',
    method: 'post',
    params
  })
}

export function exportYmsPosPayDetailSheet(data) {
  return axios({
    url: 'yms/manager/exportPosPayDetailSheet',
    method: 'post',
    data
  })
}

export function exportYmsInvoiceDetailSheet(data) {
  return axios({
    url: 'yms/manager/exportInvoiceDetailSheet',
    method: 'post',
    data
  })
}

export function listYmsPayOrderFeeInfoRelation(data) {
  return axios({
    url: 'yms/manager/listPayOrderFeeInfoRelation',
    method: 'post',
    data
  })
}

export function exportYmsMonthChargePaySheet(data) {
  return axios({
    url: 'yms/manager/exportYmsMonthChargePaySheet',
    method: 'post',
    data
  })
}

export function updateCostDate(data) {
  return axios({
    url: 'fms/business_audit/modifyCostDate',
    method: 'post',
    data
  })
}

export function listInvoiceLog(data) {
  return axios({
    url: 'fms/invoice_log/page',
    method: 'post',
    data
  })
}

export function queryByInvoiceNo(data) {
  return axios({
    url: 'fms/invoice_log/queryByInvoiceNo',
    method: 'post',
    data
  })
}

export function addAndUpdateInvoiceLog(data) {
  return axios({
    url: 'fms/invoice_log/addAndUpdateInvoice',
    method: 'post',
    data
  })
}

export function deleteInvoiceLog(data) {
  return axios({
    url: 'fms/invoice_log/remove',
    method: 'post',
    data
  })
}

export function cancelOrNormal(data) {
  return axios({
    url: 'fms/invoice_log/cancelOrNormal',
    method: 'post',
    data
  })
}

export function exportInvoiceStaticSheet(data) {
  return axios({
    url: 'fms/invoice_log/exportInvoiceStaticSheet',
    method: 'post',
    data
  })
}

export function exportInvoiceDetailSheet(data) {
  return axios({
    url: 'fms/invoice_log/exportInvoiceDetail',
    method: 'post',
    data
  })
}

// 根据财商id获取申请开票接口信息
export function queryRecordInvoiceApplyInfo(params) {
  return axios({
    url: `fms/invoice-apply/queryRecordInvoiceApplyInfo`,
    method: `get`,
    params
  })
}

export function listSecondInvoiceSubject(data) {
  return axios({
    url: 'fms/invoice-subject/listSecondInvoiceSubject',
    method: 'post',
    data
  })
}

export function submitApplyInvoiceForm(data) {
  return axios({
    url: '/fms/invoice-apply/submitApplyInvoiceForm',
    method: 'post',
    data
  })
}

export function pageInvoiceSubject(data) {
  return axios({
    url: 'fms/invoice-subject/pageInvoiceSubject',
    method: 'post',
    data
  })
}

export function listInvoiceSubjectTree() {
  return axios({
    url: 'fms/invoice-subject/listInvoiceSubjectTree',
    method: 'get'
  })
}

export function saveOrUpdateInvoiceSubject(data) {
  return axios({
    url: 'fms/invoice-subject/saveOrUpdateInvoiceSubject',
    method: 'post',
    data
  })
}

export function deleteInvoiceSubject(data) {
  return axios({
    url: 'fms/invoice-subject/deleteInvoiceSubject',
    method: 'post',
    data
  })
}

export function pageInvoiceTaxTray(data) {
  return axios({
    url: 'fms/invoice-tax-tray/pageInvoiceTaxTray',
    method: 'post',
    data
  })
}

export function saveOrUpdateInvoiceTaxTray(data) {
  return axios({
    url: 'fms/invoice-tax-tray/saveOrUpdateInvoiceTaxTray',
    method: 'post',
    data
  })
}

export function deleteTaxTray(data) {
  return axios({
    url: 'fms/invoice-tax-tray/deleteTaxTray',
    method: 'post',
    data
  })
}

export function pageInvoiceBuyerInfo(data) {
  return axios({
    url: 'fms/invoice-buyer-info/pageInvoiceBuyerInfo',
    method: 'post',
    data
  })
}

export function saveOrUpdateInvoiceBuyerInfo(data) {
  return axios({
    url: 'fms/invoice-buyer-info/saveOrUpdateInvoiceBuyerInfo',
    method: 'post',
    data
  })
}

export function deleteInvoiceBuyerInfo(data) {
  return axios({
    url: 'fms/invoice-buyer-info/deleteInvoiceBuyerInfo',
    method: 'post',
    data
  })
}

// 查询所有开票税盘信息
export function listInvoiceTaxTray() {
  return axios({
    url: `fms/invoice-tax-tray/listInvoiceTaxTray`,
    method: 'get'
  })
}

// 初始化发票代码
export function initInvoiceApplyInfo() {
  return axios({
    url: `fms/invoice-apply/init`,
    method: 'get'
  })
}

// 分页查询开票申请记录
export function pageInvoiceApplyInfo(data) {
  return axios({
    url: `fms/invoice-apply/pageInvoiceApplyInfo`,
    method: 'post',
    data
  })
}

// 根据失败记录重新申请开票
export function againApplyInvoiceForm4Fail(data) {
  return axios({
    url: `fms/invoice-apply/againApplyInvoiceForm4Fail`,
    method: 'post',
    data
  })
}

export function queryOriginalApplyByInvoiceNo(data) {
  return axios({
    url: `fms/invoice-apply/queryByInvoiceNo`,
    method: 'post',
    data
  })
}

// 作废发票，调用中远税务接口
export function cancelInvoiceApplyInfo(params) {
  return axios({
    url: `fms/invoice-apply/cancelInvoiceForm`,
    method: `get`,
    params
  })
}

export function getEInvoiceAndSendEmail(data) {
  return axios({
    url: `fms/invoice-apply/getEInvoiceAndSendEmail`,
    method: 'post',
    data
  })
}

export function togetherSendEmail(data) {
  return axios({
    url: `fms/invoice-apply/togetherSendEmail`,
    method: 'post',
    data
  })
}

export function likeSearchEmail(params) {
  return axios({
    url: `fms/invoice-apply/likeSearchEmail`,
    method: `get`,
    params
  })
}

export function queryEmailsByCustomerId(data) {
  return axios({
    url: `fms/invoice-apply/queryEmailByCustomerId`,
    method: 'post',
    data
  })
}

export function printInvoice(data) {
  return axios({
    url: `fms/invoice-apply/printInvoice`,
    method: 'post',
    data
  })
}

export function printInvoiceByAuditId(data) {
  return axios({
    url: `fms/invoice-apply/printInvoiceByAuditId`,
    method: 'post',
    data
  })
}

export function queryMonthYardAmount(params) {
  return axios({
    url: `fms/business_audit/queryMonthYardAmount`,
    method: `get`,
    params
  })
}

export function openEinvoiceUrl(data) {
  return axios({
    url: `fms/invoice-apply/openEinvoiceUrl`,
    method: 'post',
    data
  })
}

export function applyRedInfoNoSubmit(data) {
  return axios({
    url: `fms/invoice-apply/applyRedInfoNo`,
    method: 'post',
    data
  })
}

export function getInvoiceBuyer(data) {
  return axios({
    url: `fms/invoice-buyer-info/queryInvoiceBuyerInfo`,
    method: 'post',
    data
  })
}

export function queryInvoiceBuyerById(data) {
  return axios({
    url: `fms/invoice-buyer-info/queryInvoiceBuyerById`,
    method: 'post',
    data
  })
}

export function statisticInvoiceByDepartment(data) {
  return axios({
    url: `fms/invoice-apply/statisticInvoiceByDepartment`,
    method: 'post',
    data
  })
}

export function onlyApplyInvoiceFormNoOrder(data) {
  return axios({
    url: `fms/invoice-apply/onlyApplyInvoiceNoOrder`,
    method: 'post',
    data
  })
}

export function adjustInit() {
  return axios({
    url: `fms/business-adjust-info/init`,
    method: 'get'
  })
}

export function queryPageAdjusts(data) {
  return axios({
    url: `fms/business-adjust-info/pageList`,
    method: 'post',
    data
  })
}

export function saveAdjustApply(data) {
  return axios({
    url: `fms/business-adjust-info/saveAdjustApply`,
    method: 'post',
    data
  })
}

export function checkAdjustPass(data) {
  return axios({
    url: `fms/business-adjust-info/checkPass`,
    method: 'post',
    data
  })
}

export function cancelAdjust(data) {
  return axios({
    url: `fms/business-adjust-info/cancel`,
    method: 'post',
    data
  })
}

export function rejectAdjust(data) {
  return axios({
    url: `fms/business-adjust-info/reject`,
    method: 'post',
    data
  })
}

export function listFlows(data) {
  return axios({
    url: `fms/business-adjust-info/listFlows`,
    method: 'post',
    data
  })
}

export function generateAdjustCert(data) {
  return axios({
    url: `fms/business-adjust-info/generateAdjustCert`,
    method: 'post',
    data
  })
}

export function queryAdjustCertsByAdjustId(data) {
  return axios({
    url: `fms/business-adjust-info/queryAdjustCerts`,
    method: 'post',
    data
  })
}

export function downloadAdjustCert(data) {
  return axios({
    url: `fms/business-adjust-info/downloadAdjustCert`,
    method: 'post',
    data
  })
}

export function queryAdjustBalanceInfo(data) {
  return axios({
    url: `fms/business-adjust-info/queryBalanceInfo`,
    method: 'post',
    data
  })
}

export function querySaleAdjustOrder4Print(data) {
  return axios({
    url: `fms/business-adjust-info/querySaleAdjustOrder4Print`,
    method: 'post',
    data
  })
}

export function queryWmsAdjustOrder4Print(data) {
  return axios({
    url: `fms/business-adjust-info/queryWmsAdjustOrder4Print`,
    method: 'post',
    data
  })
}

export function queryYmsAdjustOrder4Print(data) {
  return axios({
    url: `fms/business-adjust-info/queryYmsAdjustOrder4Print`,
    method: 'post',
    data
  })
}

export function queryMmsAdjustOrder4Print(data) {
  return axios({
    url: `fms/business-adjust-info/queryMmsAdjustOrder4Print`,
    method: 'post',
    data
  })
}

export function listSettleMonth(data) {
  return axios({
    url: `fms/settle_month/page`,
    method: 'post',
    data
  })
}

export function createSettleMonth(data) {
  return axios({
    url: `fms/settle_month/saveMonth`,
    method: 'post',
    data
  })
}

export function updateSettleMonth(data) {
  return axios({
    url: `fms/settle_month/updateMonth`,
    method: 'post',
    data
  })
}

export function deleteSettleMonth(data) {
  return axios({
    url: `fms/settle_month/deleteMonth`,
    method: 'post',
    data
  })
}

export function listSimpleInvoiceRecord(data) {
  return axios({
    url: `fms/simple_invoice/page`,
    method: 'post',
    data
  })
}

export function deleteSimpleApply(data) {
  return axios({
    url: `fms/simple_invoice/deleteApply`,
    method: 'post',
    data
  })
}

export function applySimpleInvoice(data) {
  return axios({
    url: `fms/simple_invoice/directInvoice`,
    method: 'post',
    data
  })
}

export function queryInvoiceBuyerByCustomerId(data) {
  return axios({
    url: `fms/invoice-buyer-info/queryInvoiceBuyerByCustomerId`,
    method: 'post',
    data
  })
}

export function exportPayInvoices(data) {
  return axios({
    url: `fms/business_audit/exportPayInvoices`,
    method: 'post',
    data
  })
}

export function reissueEmail(data) {
  return axios({
    url: `fms/settle_month/reissueEmail`,
    method: 'post',
    data
  })
}

export function handleMoneyRefundApply(data) {
  return axios({
    url: `fms/money_refund/apply`,
    method: 'post',
    data
  })
}

export function getRefundApplyPage(data) {
  return axios({
    url: `fms/money_refund/pageList`,
    method: 'post',
    data
  })
}

export function businessCheckRefund(data) {
  return axios({
    url: `fms/money_refund/businessCheck`,
    method: 'post',
    data
  })
}

export function fmsCheckRefund(data) {
  return axios({
    url: `fms/money_refund/fmsCheck`,
    method: 'post',
    data
  })
}

export function fmsRejectRefund(data) {
  return axios({
    url: `fms/money_refund/fmsReject`,
    method: 'post',
    data
  })
}

export function refundCashier(data) {
  return axios({
    url: `fms/money_refund/refundCashier`,
    method: 'post',
    data
  })
}

export function deleteRefundCashier(data) {
  return axios({
    url: `fms/money_refund/deleteRefundCashier`,
    method: 'post',
    data
  })
}

export function queryRefundCert(data) {
  return axios({
    url: `fms/money_refund/queryRefundCert`,
    method: 'post',
    data
  })
}

export function generateRefundCert(data) {
  return axios({
    url: `fms/money_refund/generateRefundCert`,
    method: 'post',
    data
  })
}

export function downloadRefundCert(data) {
  return axios({
    url: `fms/money_refund/downloadRefundCert`,
    method: 'post',
    data
  })
}

<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="申请退款时间">
                <a-range-picker @change="onDateChange"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="箱号">
                <a-input v-model="queryParam.container_no" placeholder="根据箱号搜索" allowClear/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="提单号">
                <a-input v-model="queryParam.bill_no" placeholder="根据提单号搜索" allowClear/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="退款状态">
                <a-select
                  v-model="queryParam.refund_status"
                  style="width: 100%"
                  allowClear
                  placeholder="选择退款状态"
                  @change="changeRefundStatus"
                >
                  <a-select-option v-for="item in refundStatusList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="堆场">
                <a-select
                  v-model="queryParam.yard_id"
                  style="width: 100%"
                  allowClear
                  placeholder="选择堆场"
                >
                  <a-select-option v-for="item in yardList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="流水号">
                <a-input v-model="queryParam.trx_id" allowClear placeholder="请至少输入6位流水号进行查询" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="queryList">查询</a-button>
      </div>

      <a-table
        row-key="id"
        size="middle"
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleChangeTable"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="refundStatus" slot-scope="text, record">
          <a-tag :color="refundStatusColor(record.refund_status)">{{ text==='2'?"退款完成":text==='1'?"待审核":"驳回" }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a v-if="record.refund_status==='1'" @click="showAgreeRefundInfo(record)">同意退款</a>
            <a-divider type="vertical" />
            <a v-if="record.refund_status==='1'" @click="showRefuseRefundInfo(record)">驳回</a>
          </template>
        </span>
      </a-table>
      <a-modal
        :visible="agreeRefundVisible"
        :confirmLoading="agreeRefundLoading"
        title="同意退款"
        :width="720"
        @cancel="agreeRefundCancel"
        @ok="agreeRefundSubmit"
      >
        <a-form-model layout="vertical" :model="applyRefundForm">
          <a-row :gutter="24">
            <a-divider orientation="left">退款申请信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="堆场">
                <a-input v-model="applyRefundForm.yard_name" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="箱号">
                <a-input v-model="applyRefundForm.container_no" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="提单号">
                <a-input v-model="applyRefundForm.bill_no" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="原支付流水">
                <a-input v-model="applyRefundForm.old_trx_id" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="支付金额">
                <a-input v-model="applyRefundForm.old_bill_amount" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="退款金额">
                <a-input v-model="applyRefundForm.refund_amount" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="申请退款原因">
                <a-input v-model="applyRefundForm.apply_refund_reason" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="堆场审核信息">
                <a-input v-model="applyRefundForm.yard_check_info" :disabled="true" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
      <a-modal
        :visible="refuseRefundVisible"
        :confirmLoading="refuseRefundLoading"
        title="驳回退款"
        :width="720"
        @cancel="refuseRefundCancel"
        @ok="refuseRefundSubmit"
      >
        <a-form-model layout="vertical" :model="applyRefundForm" :rules="applyRefundFormRules">
          <a-row :gutter="24">
            <a-divider orientation="left">退款申请信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="堆场">
                <a-input v-model="applyRefundForm.yard_name" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="箱号">
                <a-input v-model="applyRefundForm.container_no" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="提单号">
                <a-input v-model="applyRefundForm.bill_no" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="原支付流水">
                <a-input v-model="applyRefundForm.old_trx_id" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="支付金额">
                <a-input v-model="applyRefundForm.old_bill_amount" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="退款金额">
                <a-input v-model="applyRefundForm.refund_amount" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="申请退款原因">
                <a-input v-model="applyRefundForm.apply_refund_reason" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="堆场审核信息">
                <a-input v-model="applyRefundForm.yard_check_info" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="驳回退款原因" prop="refuse_remark">
                <a-input v-model="applyRefundForm.refuse_remark" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { listApplyRefundByPage, agreeApplyRefund, handleRefuseApply } from '@/api/fms'

export default {
  name: 'YmsRefundData',
  data() {
    return {
      refundStatusList: [
        { value: '1', name: '待审核' },
        { value: '2', name: '退款完成' },
        { value: '3', name: '驳回' }
      ],
      yardList: [
        { value: 1, name: '浦东堆场' },
        { value: 2, name: '江东堆场' },
        { value: 4, name: '钢四堆场' },
        { value: 5, name: '华东堆场' }
      ],
      queryParam: { refund_status: '1' },
      waitRefundCount: 0,
      agreeRefundVisible: false,
      agreeRefundLoading: false,
      applyRefundForm: {
        // refund_apply_id: null,
        // yard_name: null,
        // container_no: null,
        // bill_no: null,
        // old_trx_id: null,
        // old_bill_amount: null,
        // refund_amount: null,
        // apply_refund_reason: null,
        // yard_check_info: null
      },
      refuseRefundVisible: false,
      refuseRefundLoading: false,
      applyRefundFormRules: {
        refuse_remark: [{ required: true, message: '请填写驳回原因', trigger: 'blur' }]
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '堆场',
          align: 'center',
          dataIndex: 'yard_name'
        },
        {
          title: '箱号',
          align: 'center',
          dataIndex: 'container_no'
        },
        {
          title: '提单号',
          align: 'center',
          dataIndex: 'bill_no'
        },
        {
          title: '操作类型',
          align: 'center',
          dataIndex: 'record_type'
        },
        {
          title: '原支付流水',
          align: 'center',
          dataIndex: 'old_trx_id',
          customRender: (text) => {
            return text != null ? text : '-'
          }
        },
        {
          title: '支付金额',
          align: 'center',
          dataIndex: 'old_bill_amount'
        },
        {
          title: '退款状态',
          align: 'center',
          dataIndex: 'refund_status',
          scopedSlots: { customRender: 'refundStatus' }
        },
        {
          title: '申请退款金额',
          align: 'center',
          dataIndex: 'refund_amount'
        },
        {
          title: '申请退款原因',
          align: 'center',
          dataIndex: 'apply_refund_reason'
        },
        {
          title: '堆场审核信息',
          align: 'center',
          dataIndex: 'yard_check_info'
        },
        {
          title: '申请时间',
          align: 'center',
          dataIndex: 'created_at'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      listApplyRefundByPage(this.queryParam).then(res => {
        this.pagination.total = res.total
        this.data = res.list
      })
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['page_size'] = page.pageSize
      this.loadData()
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParam['page'] = 1
      this.queryParam['page_size'] = 10
      this.loadData()
    },
    changeRefundStatus() {
        this.loadData()
    },
    showAgreeRefundInfo(record) {
        this.applyRefundForm = record
        this.agreeRefundVisible = true;
    },
    agreeRefundCancel() {
        this.agreeRefundVisible = false;
        this.agreeRefundLoading = false;
    },
    agreeRefundSubmit() {
        this.$confirm({
        title: '确认提示',
        content: '确定要退款？',
        onOk: () => {
          agreeApplyRefund({ refund_apply_id: this.applyRefundForm.id }).then(res => {
            this.$notification['success']({
                message: '提示',
                description: '退款成功.'
              })
              this.loadData()
              this.agreeRefundCancel()
          })
        }
      })
    },
    showRefuseRefundInfo(record) {
        this.applyRefundForm = record
        this.refuseRefundVisible = true;
    },
    refuseRefundCancel() {
        this.refuseRefundVisible = false;
        this.refuseRefundLoading = false;
    },
    refuseRefundSubmit() {
        if (!this.applyRefundForm.refuse_remark) {
            this.$message.warning('请填写驳回原因')
            return
        }
        this.$confirm({
        title: '确认提示',
        content: '确定要驳回退款申请？',
        onOk: () => {
          handleRefuseApply({ refund_apply_id: this.applyRefundForm.id, refuse_remark: this.applyRefundForm.refuse_remark }).then(res => {
            this.$notification['success']({
                message: '提示',
                description: '驳回成功.'
              })
              this.loadData()
              this.refuseRefundCancel()
          })
        }
      })
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['refund_start_date'] = startDate
        this.queryParam['refund_end_date'] = endDate
      } else {
        this.queryParam['refund_start_date'] = null
        this.queryParam['refund_end_date'] = null
      }
    },
    refundStatusColor(value) {
      switch (value) {
        case '1':
          return '#faad14'
        case '2':
          return '#4cd964'
        case '3':
          return '#ff4d4f'
      }
    }
  }
}
</script>

<style scoped>

</style>
